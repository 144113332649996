/* Overall Container */
.crop-details {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #344e41;
}

/* Form Container */
.crop-details-form-container {
  width: 80%;
  height: 90vh;
  max-width: 900px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
}

/* Image Section */
.image-section {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
}

.crop-image {
  width: 250px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.add-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 150px;
  background-color: #5a7c6b;
  border-radius: 8px;
  cursor: pointer;
}

.add-more-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
}

.add-more-icon {
  font-size: 2.5rem;
}
.add-more-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-more-text p,
span {
  padding: 0;
  margin: 0;
  font-size: 0.7rem;
}
/* Form Styles */
.form-content {
  width: 40%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.disease-details-form-label {
  font-size: 1.1rem;
  color: #344e41;
  font-weight: 500;
}

.disease-details-form-input {
  padding: 4px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #5a7c6bb2;
}
.additional-info {
  margin: 0;
  padding: 0;
  font-size: 1.2rem !important;
}
.additional-info-feedback {
  font-size: 0.8rem !important;
  font-weight: 600;
}
/* Feedback Section */
.additional-info {
  font-size: 16px;
  color: #344e41;
  margin-top: 10px;
}

.form-radio-group {
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-radio-label {
  font-size: 14px;
  color: #344e41;
}

/* Comment Box */
.form-textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  outline: none;
  background-color: #5a7c6bb2;
}
