/* farm report body style */
.farm-report-body {
  height: 100vh;
  width: 100vw;
  background-color: #5a7c6b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.farm-report-image {
  position: absolute;
}

.instruction-text {
  font-size: 1.3rem;
  color: #d9d9d9;
  font-weight: 700;
  z-index: 2;
}

.generate-report-button {
  position: "absolute";
  z-index: 2;
  margin-top: "25rem";
}
