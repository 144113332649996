.farm-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.farm-map-container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.farm-map-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.map-controls {
  position: absolute;
  z-index: 1000;
  height: 80%;
}
#field-dropdown {
  background-color: #5a7c6bb2 !important;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  left: -10rem;
  top: 1rem;
  cursor: pointer;
}

#field-dropdown option:checked {
  background-color: #5a7c6ba6 !important;
  color: white;
}

#field-dropdown:hover {
  background-color: #5a7c6ba6 !important;
}

#field-dropdown option {
  background-color: #5a7c6ba6;
}

.field-actions {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: center;
  z-index: 1000;
}

.add-new-field-container {
  color: #fff;
  font-size: 1rem;
  background-color: #5a7c6b;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 33rem;
  position: absolute;
  z-index: 1000;
}

.actions-container {
  width: 95%;
  margin: auto;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1000;
  padding: 5px 10px;
  background-color: #5a7c6b;
  border-radius: 5px;
}

.actions-container img {
  height: 1.5rem;
}

.action-right {
  border-left: 1px solid #fff !important;
  padding-left: 0.5rem;
}

.action-left {
  border-right: 1px solid #fff !important;
}

.actions-container :nth-child(1) {
  padding: 3px 4px;
  background-color: #5a7c6b;
  border-radius: 5px;
}

.actions-container button {
  background-color: transparent;
  border: none;
}

.actions-container button img {
  height: 1.5rem;
  background-color: transparent !important;
}
