.soil-info {
  width: 90%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.soil-stat {
  background-color: #0b8a80;
  display: flex;
  width: 50%;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
}
.soil-stat .icon-container img {
  height: 50px;
  width: 50px;
}
.soil-stat .icon-container {
  width: 40%;
}
.soil-stat .data-container {
  text-align: center;
  width: 100%;
}
.soil-stat .data-container p {
  font-size: 0.7rem;
  color: #fff;
}
.soil-stat .data-container strong {
  color: #fff;
}
