/* Hide scrollbar for the Dashboard component */
.dashboard {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.dashboard::-webkit-scrollbar {
  display: none;
}
