/* Container for the entire page */
.personal-info-container {
  max-width: 98%;
  height: 99%;
  margin: auto;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

/* Header styling */
.personal-info-header {
  text-align: start;
  margin-bottom: 10px;
  border-bottom: 1px solid #00000066;
}

.personal-info-header h2 {
  font-size: 1.2rem;
  color: #344e41;
}

/* Profile image styling */
.profile-image-container {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image-container img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

/* Form layout and styling */
.personal-info-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 77%;
  padding: 10px;
}

/* Layout for form fields */
.form-layout {
  display: flex;
  justify-content: start;
}

/* Columns for the form */
.form-column {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 15rem;
}

.form-group label {
  font-size: 0.8rem;
  margin-bottom: 5px;
  width: 100%;
  color: #344e41;
}

.form-group input {
  padding: 5px;
  border: 1px solid #344e41;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  width: 100%;
}

/* Save button styling */
.form-actions {
  display: flex;
  justify-content: center;
}

.save-button {
  background-color: #344e41;
  color: #fff;
  font-weight: 500;
  border: none;
  padding: 5px 80px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.save-button:hover {
  background-color: #344e41;
}
