/* General styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Upload screen styles */
.uploadScreen {
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.uploadBox {
  width: 60vw !important;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  border: 3px dotted #fff;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  top: 20px;
}
.uploadBox img {
  height: 14rem;
  width: 14rem;
}

.uploadText {
  margin-top: 56px;
  text-align: center;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
}

.uploadLink {
  color: #00b2eb;
  margin: 5px 5px;
}

.uploadButton {
  margin-top: 12px;
  padding: 8px 80px;
  background-color: white;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  color: #344e41;
}
