.humidity-card {
  margin-top: 20px;
  margin: 20px 10px 10px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff !important;
}

.humidity-chart-container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.humidity-echarts {
  width: 100%;
  height: 200px !important;
  padding: 0;
  margin: 0;
}
