.crop-health {
  background-color: #ffffff !important;
}
.crop-health-title {
  color: #344e41;
  font-size: 1.3rem;
  font-weight: 600;
}

.crop-health .crop-health-crop-image {
  border: 2px solid #5a7c6b;
  border-radius: 5px;
  padding: 5px;
}

.crop-health .crop-information tr {
  line-height: 2rem;
  margin-left: 1rem;
}

.crop-health .crop-information th {
  font-size: 1rem;
  font-weight: 600;
  color: #344e41;
}

.crop-health .crop-information td {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  padding-left: 10px;
}

.soil-analysis {
  color: #344e41;
  font-size: 1.4rem;
  font-weight: 600;
}
