.container {
  margin-top: 50px;
}
.form-label {
  font-size: 0.8rem;
  color: #fff;
}
.form-control-sm {
  height: 10px !important;
  font-size: 14px;
}
.signup-form .form-input {
  background-color: #075a53;
  border: none;
  outline: none;
  width: 100%;
  padding: 3px 5px;
  border-radius: 5px;
  color: #9a9898;
  font-size: 0.9rem;
}

.form-check-label {
  font-size: 0.8rem;
  color: #fff;
  line-height: 22px;
}
.submit-button {
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 3px 0;
  background-color: #3592fd;
  color: #fff;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .col-md-6 {
    width: 100%;
  }

  .btn-primary {
    font-size: 14px;
    padding: 8px;
  }
}
