.wind-chart-card {
  margin-top: 20px;
  margin: 20px 10px 0px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff !important;
}
.wind-chart-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wind-chart-heading h2 {
  color: #344e41;
  font-size: 1.3rem;
  font-weight: 700;
}
.wind-chart-heading div {
  display: flex;
}
.wind-chart-heading p {
  margin: 0;
  padding: 0;
  padding: 0 5px;
  color: #a7a5a5;
  font-size: 0.8rem;
}
.wind-info {
  display: flex;
  margin-left: 3rem;
  text-align: center;
}

.wind-info p {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  color: #9a9898;
}
.wind-info h2 {
  color: #344e41;
  font-size: 1.5rem;
}
.wind-chart-container {
  position: relative;
}

.wind-chart-echarts {
  width: 100%;
  height: 200px !important;
}
