/* General Layout */
.add-field-map-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.map-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.add-field-main-container {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: auto;
}

.map-header .back-btn {
  cursor: pointer;
}

/* Map Container */
.map-container {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Map Controls */
.map-controls {
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  height: 100vh;
  gap: 15px;
  z-index: 1000;
}

.map-controls button {
  padding: 5px;
  background-color: #075a53;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.map-controls button:hover {
  background-color: #064841;
}

/* Create Farm Button */
.create-farm-container {
  position: absolute;
  bottom: 20px;
  width: 30%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.create-farm-container button {
  background-color: #075a53;
  border: none;
  color: #fff;
  font-weight: 600;
  width: 50%;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.create-farm-container button:hover {
  background-color: #064841;
}

.add-field .add-field-button {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: center;
  z-index: 1000;
}

.add-field .add-field {
  color: #fff;
  font-size: 1rem;
  background-color: #5a7c6b;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.add-field-button {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1000;
  padding: 5px 10px;
}

.add-field-button img {
  height: 1.5rem;
}

.add-field-button .add-field {
  color: #fff;
  font-size: 1rem;
  background-color: #5a7c6b;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}
.add-field-right-arrow {
  border-left: 1px solid hsl(0, 0%, 100%) !important;
  padding-left: 0.5rem;
}
.add-field-left-arrow {
  border-right: 1px solid #fff !important;
}
.add-field-button {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1000;
  padding: 5px 10px;
}
.add-field-button :nth-child(1) {
  padding: 3px 4px;
  background-color: #5a7c6b;
  border-radius: 5px;
}
.add-field-button .add-field {
  color: #fff;
  font-size: 1rem;
}

.add-field-button button {
  background-color: transparent;
  border: none;
}
.add-field-button button img {
  height: 1.5rem;
  background-color: transparent !important;
}

.save-farm-button {
  position: absolute;
  top: 450px;
  left: 10px;
  z-index: 1000;
}
.save-farm-btn:hover {
  background-color: #5a7c6ba6 !important;
  color: #fff !important;
}

.save-farm-btn {
  color: white;
  border: none;
  outline: none;
  background-color: #5a7c6ba6;
}

.save-farm-btn:hover {
  background-color: #5a7c6b !important;
  color: #fff;
}
