.weather {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.weather-sidebar {
  width: 28vw;
  margin: 0;
  padding: 0;
}
.weather-sidebar-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #344e41;
  padding: 0.6rem;
  cursor: pointer;
}
.weather-sidebar-heading:nth-child(0) {
  display: flex;
  flex-direction: row;
}

.weather-sidebar-heading h2 {
  font-size: 1rem;
  font-weight: 700;
  color: #344e41;
}
.field {
  border-bottom: 1px solid #344e41;
  padding: 1rem;
}
.field h2 {
  font-size: 1rem;
  font-weight: 500;
  color: #344e41;
}
.field-info {
  display: flex;
  justify-content: space-around;
}
.field-info h4 {
  color: #344e41;
  font-size: 1rem;
  font-weight: 200;
}
.field-info .field-details {
  display: flex;
}
.field-info .ha {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  color: #a2a2a2;
  margin-bottom: 0.2rem;
}
.field-info .field-details p {
  font-size: 0.7rem;
  color: #a2a2a2;
  margin-right: 1rem;
}
.weather-body {
  width: 100%;
  background-color: #5f7e6f;
  margin: 0;
  padding: 0;
}
