.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: fadeIn 1s ease-in-out;
  background: transparent;
}

.loading-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: bounceIn 1s ease-in-out;
  background: #ffffff;
}

.loading-text {
  margin-top: 5px;
  color: #6c757d;
  font-size: 1.2rem;
  animation: pulsate 1.5s infinite ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
