.insights-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.insights-titel-container {
  display: flex;
}
.insights-titel {
  font-size: 1.2rem;
  color: #344e41;
  font-weight: 600;
}
.insights-titel-container .dropdown-arrow {
  margin: 0;
  padding: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin-left: 0.9rem;
  margin-top: 0.3rem;
}
.insights-titel-container .dropdown-arrow div {
  margin: 0;
  padding: 0;
}
.action-title-container {
  display: flex;
  margin-right: 15rem;
}

.insight-action-title {
  font-size: 1.2rem;
  color: #344e41;
  font-weight: 600;
}
.action-title-container .dropdown-arrow {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin-left: 0.9rem;
  margin-top: 0.3rem;
}

.insight-see-all {
  color: #a2a2a2;
  font-size: 0.7rem;
}

.insight-container {
  display: flex;
  align-items: center;
}
.insight-icon {
  margin-right: 1rem;
}
.icon-stress {
  width: 30px;
  height: 30px;
  background-color: #ff7171;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-shallow-irrigation {
  width: 30px;
  height: 30px;
  background-color: #ffbd59;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-add-irrigation {
  width: 30px;
  height: 30px;
  background-color: #ffbd59;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insight-details {
  margin-left: 10px;
}

.insight-title {
  font-size: 0.938;
  font-weight: 600;
}

.insight-description {
  color: #888;
  font-size: 0.7em;
}

.insight-actions {
  display: flex;
  gap: 20px;
  margin-left: 18rem;
}

.action-button {
  padding: 4px 7px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.action-button.active {
  background-color: #f0f0f0;
}
