/* Container styling */
.weather-settings-container {
  max-width: 98%;
  height: 99%;
  margin: auto;
  padding: 10px;
  border: 2px solid #c0d6df;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Header styling */
.weather-settings-header {
  text-align: start;
  margin-bottom: 10px;
  border-bottom: 1px solid #00000066;
}

.weather-settings-header h2 {
  font-size: 1.2rem;
  color: #344e41;
}

/* Input section within each setting */
.setting-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.setting-input label {
  font-size: 14px;
  color: #6b6b6b;
}

.input-box {
  width: 100%;
  max-width: 120px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #c0d6df;
  border-radius: 4px;
}

.alert-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #344e41;
  border-radius: 8px;
  padding: 5px;
  width: 35%;
}

.label-text {
  font-size: 0.7rem;
  color: #344e41;
  padding: 3px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.alert-mark {
  display: flex;
  flex-direction: column;
}

.alert-mark label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.alert-mark input {
  width: 35%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  border: 1px solid #344e41;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
