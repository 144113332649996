.social-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.facebook-btn,
.google-btn {
  border: none;
  outline: none;
  border-radius: 5px;
  height: 2rem;
  width: 95%;
  margin: 5px 0;
  display: flex;
  align-items: center;
}
.google-btn {
  background-color: #fff !important;
  color: #9a9898;
  font-weight: 600;
}

.facebook-btn {
  background-color: #1877f2;
  font-weight: 600;
}
