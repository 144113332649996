.ant-modal-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-operation-title {
  color: #344e41;
  font-size: 1.2rem;
}
.add-operation-form-label .ant-form-item-label > label {
  font-size: 0.9rem;
  color: #344e41 !important;
  font-weight: 400;
  outline: none;
}

.add-operation-form-submit-button {
  background-color: #344e41;
  padding: 0 50px;
  font-size: 1rem;
  font-weight: 700;
}
