/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.weekweather-card {
  margin-top: 20px;
  margin: 20px 10px 0px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff !important;
}

.forecast-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* border-radius: 8px; */
  overflow: hidden;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  max-width: 100%;
  margin: auto;
}

.forecast-container .day-card {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-right: 1px solid #dfe3e8;
}

.forecast-container .day-card:last-child {
  border-right: none;
}

.forecast-container .date {
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
}

.forecast-container .icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.forecast-container .temperature {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.forecast-container .rain {
  font-size: 14px;
  color: #666;
}
