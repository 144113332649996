.rain-chances-card {
  margin-top: 20px;
  margin: 20px 10px 0px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff !important;
}
.rain-chances-card p {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
}

.rain-chances-container {
  position: relative;
  background-color: white;
}

.chart-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chart-heading h2 {
  color: #344e41;
  font-size: 1.3rem;
  font-weight: 700;
}
.chart-heading div {
  display: flex;
}
.chart-heading p {
  margin: 0;
  padding: 0;
  padding: 0 5px;
  color: #9a9898;
  font-size: 0.8rem;
}
/* top info style */
.top-info {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #a2a2a2;
  padding-bottom: 10px;
  align-items: center;
  margin-left: 2rem;
}
.top-info .details {
  display: flex;
  flex-direction: row;
}
.top-info .date {
  color: #344e41;
  text-align: center;
}
.top-info .date p {
  font-size: 1.3rem;
  font-weight: 700;
}
.top-info .temp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.top-info .temp p {
  color: #a7a5a5 !important;
  font-size: 0.7rem;
  font-weight: 600;
}
.se {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: #a7a5a5 !important;
}
.se h2 {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #344e41;
}
.rain-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
}
.rain-info p {
  color: #a7a5a5 !important;
  font-size: 0.7rem;
  font-weight: 600;
}
.rain-chances-chart {
  height: 150px !important;
  width: 100%;
  margin-top: 10px;
  padding: 0px;
  margin: 0;
}

.scroll-icon {
  font-size: 24px;
  cursor: pointer;
  color: black;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-icon.disabled {
  color: gray;
  cursor: not-allowed;
}
