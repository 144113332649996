.add-field-sidebar {
  width: 20vw;
  margin: 0;
  padding: 0;
}

.add-field-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #344e41;
  padding: 0.6rem;
  cursor: pointer;
}

.field-info.selected-field {
  background-color: #5a7c6b;
}

.selected-title {
  color: #fff !important;
}

.add-field-heading h2 {
  font-size: 1rem;
  font-weight: 700;
  color: #344e41;
}

.add-field-list h2 {
  font-size: 1rem;
  font-weight: 500;
  color: #344e41;
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.add-field-list .field-info {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #344e41;
  padding-top: 1rem;
  cursor: pointer;
}

.add-field-list .field-info h4 {
  color: #344e41;
  font-size: 1rem;
  font-weight: 400;
}

.add-field-sidebar .field-info .field-details {
  display: flex;
}

.add-field-sidebar .field-info .ha {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  color: #a2a2a2;
  margin-bottom: 0.2rem;
}

.add-field-sidebar .field-info .field-details p {
  font-size: 0.7rem;
  color: #a2a2a2;
  margin-right: 1rem;
}

.add-field-sidebar .crop-details-container .crop-details-title {
  margin-top: 0.3rem;
  color: #344e41;
}
.add-field-sidebar .crop-details-container .default-name {
  font-size: 0.5rem !important;
}

.add-field-sidebar .crop-details-container {
  padding-left: 10px;
}
.add-field-sidebar .crop-details-container label {
  color: #344e41;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 0.9rem;
}

.crop-name-container,
.crop-variety-container,
.sowing-name-container,
.type-of-irrigation-container,
.farm-name-container {
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  cursor: pointer;
}
.add-field-sidebar .crop-details-container input,
.select {
  border: 1px solid #344e41;
  width: 90%;
  outline: none;
  border-radius: 5px;
  padding: 0px 5px;
}

.add-field-sidebar .crop-details-container .select {
  font-size: 0.8rem;
  cursor: pointer;
  padding: 4px 5px;
}
.add-field-sidebar .add-field-submit-button {
  display: flex;
  justify-content: center;
  height: 10rem;
  align-items: end;
}
.add-field-sidebar .add-field-submit-button button {
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #344e41;
  color: #ffffff;
  font-weight: 600;
  width: 60%;
  height: 2rem;
}
.crop-variety-container {
  font-size: 1rem;
}

#sowingDate::-webkit-input-placeholder {
  font-size: 12px;
  color: #888;
}

#sowingDate::-moz-placeholder {
  font-size: 12px;
  color: #888;
}

#sowingDate:-ms-input-placeholder {
  font-size: 12px;
  color: #888;
}

#sowingDate::placeholder {
  font-size: 12px;
  color: #888;
}

#sowingDate {
  font-size: 14px;
  padding: 2px 5px;
}
