/* Base FullCalendar Styles */
.fc {
  color: white; /* Text color */
  height: auto;
}

/* Calendar Container */
.calendar-container {
  padding: 20px !important;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.calendar-header {
  display: flex;
  margin-bottom: 20px;
  background-color: #5a7c6b;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.calendar-header .top-left {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.calendar-header select,
.calendar-header .filter-button {
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 3px 13px;
  font-weight: 500;
  outline: none;
}

.calendar-header .long-button {
  background-color: #d9d9d9;
  display: flex;
  justify-content: space-around;
  width: 40%;
  border-radius: 8px;
}

.calendar-header .long-button button {
  margin: 2px 4px;
  padding: 0px 17px;
  font-size: 0.7rem;
  border: none;
  background-color: transparent;
  border-radius: 8px;
}
.selected-button {
  background-color: #fff !important;
}
.calendar-header .top-right {
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

.calendar-header .top-right .button-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 3rem;
}

.calendar-header .top-right .button-group button {
  border: none;
  padding: 0 7px;
  font-size: 1.1rem;
  font-weight: 800;
  color: #344e41;
}
.calendar-header .top-right .button-group .left-arrow {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: 600;
  padding: 0 10px;
}
.calendar-header .top-right .button-group .right-arrow {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 600;
  padding: 0 10px;
}

.calendar-header .top-right .add-option-button {
  border: none;
  background-color: #344e41;
  color: white;
  font-size: 0.8rem;
  border-radius: 5px;
  padding: 0 5px;
}

/* Custom Buttons */
.filter-btn {
  background-color: #3c5447;
  color: white;
}

.view-btn {
  background-color: #28a745;
  color: white;
}

.nav-btn {
  background-color: #ffc107;
  color: white;
}

.add-opp-btn {
  background-color: #007bff;
  color: white;
}

button:hover {
  opacity: 0.8;
}

/* Day Grid View Styles */
.fc-daygrid-day-top {
  background-color: #4e6b56;
  border-radius: 5px;
}

.fc-event {
  background-color: #378006;
  color: white;
  border-radius: 4px;
}

.fc-day-today,
.fc-timegrid-day.fc-today {
  background-color: transparent !important;
}

/* Toolbar Styling */
.fc-toolbar {
  background-color: #3c5447;
  color: white;
}

.fc-button {
  background-color: #5a7c6b;
  color: white;
  border-radius: 5px;
}

.fc-button:hover {
  background-color: #3c5447;
}

/* Title Customization */
.fc-col-header-cell-cushion {
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: capitalize;
}

/* Day Grid Cell Adjustments */
.fc-daygrid-day {
  height: 0px !important;
  border-bottom: none !important;
}

.fc-daygrid-day-frame,
.fc-timegrid-slot,
.fc-scroller {
  overflow-y: auto !important;
}

.fc-timegrid-slot {
  height: 70px !important;
}

/* Scrollbar Customization */
.fc-scroller::-webkit-scrollbar {
  width: 8px;
}

.fc-scroller::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Optional Slot Line Removal */
.fc-timegrid-slot-line {
  display: none !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .calendar-header {
    flex-direction: column;
    align-items: center;
  }

  .month-selector {
    margin-bottom: 10px;
  }

  .view-btn,
  .nav-btn {
    margin: 5px 0;
  }
}

.calendar-container-body {
  display: flex;
  justify-content: center;
}

.ant-modal {
  position: absolute;
}
